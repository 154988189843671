import { SET_MAIL_MESSAGE, SET_MAIL_MESSAGES } from './-mutation-types';
import { useMailMessagesStore } from '~/stores/mail-messages';
import type { MailMessage } from '~/models/MailMessage';
import type { PreviewMailMessage } from '~/models/PreviewMailMessage';

const Mutations = {
    [SET_MAIL_MESSAGE](payload: MailMessage | PreviewMailMessage | null) {
        const mailMessagesStore = useMailMessagesStore();
        mailMessagesStore.mailMessage = payload;
    },
    [SET_MAIL_MESSAGES](payload: MailMessage[] | []) {
        const mailMessagesStore = useMailMessagesStore();
        mailMessagesStore.mailMessages = payload;
    },
};

export default Mutations;
