/* eslint-disable vue/sort-keys */

import { defineStore } from 'pinia';
import { DefaultState } from './mail-messages/state';
import Getters from './mail-messages/getters';
import Actions from './mail-messages/actions';
import Mutations from './mail-messages/mutations';

export const useMailMessagesStore = defineStore(
    'mail-messages',
    {
        state: DefaultState,
        getters: Getters,
        actions: {
            ...Actions,
            ...Mutations,
        },
    },
);
