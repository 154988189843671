import { SET_MAIL_MESSAGE, SET_MAIL_MESSAGES } from './-mutation-types';
import { useMailMessagesStore } from '~/stores/mail-messages';
import MailMessageService from '~/services/api/MailMessageService';
import type { MailMessage } from '~/models/MailMessage';

const Actions = {
    async fetchMailMessage(mailMessageId: number) {
        const mailMessagesStore = useMailMessagesStore();

        const mailMessageService = new MailMessageService();
        const response = await mailMessageService.fetchMailMessage(mailMessageId);

        mailMessagesStore[SET_MAIL_MESSAGE](response);
    },
    async fetchMailMessagePreview(mailMessageId: number) {
        const mailMessagesStore = useMailMessagesStore();

        const mailMessageService = new MailMessageService();
        const response = await mailMessageService.fetchMailMessagePreview(mailMessageId);

        mailMessagesStore[SET_MAIL_MESSAGE](response);
    },
    setMailMessage(mailMessage: MailMessage) {
        const mailMessagesStore = useMailMessagesStore();
        mailMessagesStore[SET_MAIL_MESSAGE](mailMessage);
    },
    setMailMessages(mailMessages: MailMessage) {
        const mailMessagesStore = useMailMessagesStore();
        mailMessagesStore[SET_MAIL_MESSAGES](mailMessages);
    },
};

export default Actions;
