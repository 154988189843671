import { GET_MAIL_MESSAGE, GET_MAIL_MESSAGES } from './-getter-types';
import { useMailMessagesStore } from '~/stores/mail-messages';
import type { MailMessage } from '~/models/MailMessage';

const Getters = {
    [GET_MAIL_MESSAGE]: (): MailMessage | null => {
        const mailMessagesStore = useMailMessagesStore();

        return mailMessagesStore.mailMessage;
    },
    [GET_MAIL_MESSAGES]: (): MailMessage[] => {
        const mailMessagesStore = useMailMessagesStore();

        return mailMessagesStore.mailMessages;
    },
};

export default Getters;
